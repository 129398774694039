<template>
  <div class="shadowed-box">
    <div class="resource-list static-list">
      <div class="list-header">
        <div class="row tight-columns vertical-center-not-xs">
          <div class="col-sm-4">
            <dynamic-order-arrows
              class="vertical-center"
              :order="order"
              order-by="name"
              :initial-order="initialSortOrder('name')"
              :on-sort="sort"
              :reverse="true">
              <div>
                Firm Name
              </div>
            </dynamic-order-arrows>
          </div>

          <div class="col-sm-3">
            <dynamic-order-arrows
              class="vertical-center avg-score"
              :order="order"
              order-by="averageRecommendationScore"
              :initial-order="initialSortOrder('averageRecommendationScore')"
              :on-sort="sort">
              <div class="vertical-center">
                <svg-icon id="avg-firm-tooltip" name="info" class="base-icon smaller right-5"></svg-icon>
                <tooltip
                  target="#avg-firm-tooltip"
                  placement="bottom"
                  custom-class="tooltip-white"
                  text="The displayed score represents an average of all Recommendation Scores for this law firm in Scout, based on the law-firm-level reviews submitted by your colleagues. The maximum possible recommendation score is 10. This information is entirely internal to your team.">
                </tooltip>
                <div>
                  Average Score
                </div>
              </div>
            </dynamic-order-arrows>
          </div>

          <div class="col-sm-2">
            <dynamic-order-arrows
              class="vertical-center"
              :order="order"
              order-by="reviewCount"
              :initial-order="initialSortOrder('reviewCount')"
              :on-sort="sort">
              <div>
                Review Count
              </div>
            </dynamic-order-arrows>
          </div>

          <div class="col-sm-2">
            <dynamic-order-arrows
              class="vertical-center"
              :order="order"
              order-by="reviewerCount"
              :initial-order="initialSortOrder('reviewerCount')"
              :on-sort="sort">
              <div>
                Reviewer Count
              </div>
            </dynamic-order-arrows>
          </div>
        </div>
      </div>

      <div class="clearfix" v-if="hasLawFirms">
        <loading-section name="law-firm-reviews-sort">
          <law-firm-list-item
            v-for="lawFirm in lawFirms"
            :key="`law-firm-${lawFirm.id}`"
            :id="`law-firm-${lawFirm.id}`"
            :law-firm="lawFirm"
            :expanded="lawFirm.id === lawFirmId"
            :review-template="reviewTemplate">
          </law-firm-list-item>
        </loading-section>
      </div>

      <div class="box-content" v-else>
        <div class="gray-text size-text-16px top-10 bottom-10">
          No Results
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LawFirmListItem from 'vue-app/scout/client/reviews/law-firm-list-item.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import DynamicOrderArrows from 'vue-app/shared/components/dynamic-order-arrows.vue';

export default {
  name: 'LawFirmsList',

  components: {
    DynamicOrderArrows,
    LawFirmListItem,
    LoadingSection
  },

  props: {
    lawFirms: {
      type: Array,
      default: () => []
    },

    lawFirmId: {
      type: Number,
      default: null
    },

    reviewTemplate: {
      type: Object,
      required: true
    },

    onSort: {
      type: Function,
      required: true
    }
  },

  data: function () {
    return {
      order: { name: 'asc' }
    };
  },

  computed: {
    hasLawFirms() {
      return !!this.lawFirms.length;
    }
  },

  methods: {
    initialSortOrder(sortBy) {
      return {
        name: 'asc',
        averageRecommendationScore: 'desc',
        reviewCount: 'desc',
        reviewerCount: 'desc'
      }[sortBy];
    },

    sort(sortBy) {
      let sortOrder;

      if (this.order[sortBy]) {
        sortOrder = this.order[sortBy] === 'asc' ? 'desc' : 'asc';
      }
      else {
        sortOrder = this.initialSortOrder(sortBy);
      }

      this.order = { [sortBy]: sortOrder };
      this.onSort(sortBy, sortOrder);
    }
  }
};
</script>

<style lang="scss" scoped>
  .avg-score {
    @media (min-width: 768px) {
      justify-content: center;
    }
  }
</style>
