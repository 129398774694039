<template>
  <div class="requests-list">
    <request-list-item
      v-for="request in counselRequests"
      :request="request"
      :expanded-by-parent="expandAll"
      :key="request.id"
      :option-lists="optionLists"
      :on-update="onUpdate">
    </request-list-item>
  </div>
</template>

<script>
import RequestListItem from './request-list-item.vue';

export default {
  name: 'RequestsList',

  components: {
    RequestListItem
  },

  props: {
    counselRequests: {
      type: Array,
      required: true
    },

    expandAll: {
      type: Boolean,
      default: false
    },

    optionLists: {
      type: Object,
      required: true
    },

    onUpdate: {
      type: Function,
      required: true
    }
  }
};
</script>
