<template>
  <div :class="['list-item matter-row no-hover', { 'expanded': isExpanded }]">
    <div class="row tight-columns vertical-center-not-xs">
      <div class="col-sm-4 clickable" @click="viewScoutLawyer(lawyer.id, 'reviews')">
        <lawyer-name-plate :lawyer="lawyer" photo-size="small"></lawyer-name-plate>
      </div>

      <div class="col-sm-3 top-10-xs text-center-not-xs">
        <rating-circle class="inline-block vertical-middle right-5" :rating="rating"></rating-circle>
        <span class="bold-weight">{{ displayScore }}</span>
      </div>

      <div class="col-sm-2 top-10-xs">
        <div class="size-text-12px semibold-weight">
          Review Count
        </div>

        <div>
          {{ reviewCount }}
        </div>
      </div>

      <div class="col-sm-2 top-10-xs">
        <div class="size-text-12px semibold-weight">
          Reviewer Count
        </div>

        <div>
          {{ reviewersCount }}
        </div>
      </div>

      <div class="col-sm-1 top-10-xs text-right-not-xs">
        <button type="button" class="nv-button-square-white" @click="onToggleReviews"><svg-icon name="caret-right" :class="['base-icon disclosure-icon', { 'expanded rotate-90': isExpanded }]"></svg-icon></button>
      </div>
    </div>

    <div class="top-20" v-if="isExpanded">
      <div :class="{ 'bottom-20': sortedReviews.length }">
        <div class="text-right-not-xs">
          <a :href="`/scout-company/lawyers/${lawyer.id}/reviews/new`" class="semibold-weight dark-gray-link" target="_self">Leave a Review</a>
        </div>
      </div>

      <div class="list-spacing-10" v-for="review in sortedReviews" :key="review.id">
        <review-list-item :review="review" :review-template="reviewTemplate"></review-list-item>
      </div>
    </div>
  </div>
</template>

<script>
import ReviewListItem from 'vue-app/scout/shared/review-list-item.vue';
import LawyerNamePlate from 'vue-app/scout/shared/lawyer-name-plate.vue';
import RatingCircle from 'vue-app/scout/shared/rating-circle.vue';
import { concat, filter, flatMap, uniqBy } from 'lodash';

export default {
  name: 'ScoutLawyerReviewListItem',

  components: {
    ReviewListItem,
    LawyerNamePlate,
    RatingCircle
  },

  props: {
    lawyer: {
      type:    Object,
      default: () => ({})
    },

    expanded: {
      type:    Boolean,
      default: false
    },

    reviewTemplate: {
      type:     Object,
      required: true
    }
  },

  data: function () {
    return {
      isExpanded: this.expanded,
      maxScore: 10
    };
  },

  computed: {
    reviewCount() {
      return this.lawyer.reviews.length;
    },

    reviewers() {
      return uniqBy(flatMap(this.lawyer.reviews, 'reviewer'), (review) => {
        return review.id || `${review.businessName}.${review.externalId}.${review.fullName}`;
      });
    },

    reviewersCount() {
      return this.reviewers.length;
    },

    generalReviews() {
      return filter(this.lawyer.reviews, (review) => { return !review.scoutMatterId; });
    },

    matterReviews() {
      return filter(this.lawyer.reviews, (review) => { return review.scoutMatterId; });
    },

    sortedReviews() {
      return concat(this.generalReviews, this.matterReviews);
    },

    score() {
      return this.lawyer.reviewsSummary.score;
    },

    rating() {
      const percent = this.score ? this.score / this.maxScore * 100 : 0;
      return { percent: percent };
    },

    displayScore() {
      return this.score ? `${Number(this.score).toFixed(1)}/10` : 'N/A';
    }
  },

  methods: {
    onToggleReviews() {
      this.isExpanded = !this.isExpanded;
    }
  }
};
</script>
