<template>
  <div :class="['list-item matter-row no-hover extra-padded', { 'expanded': showExpandedData }]">
    <div class="row tight-columns vertical-center-not-xs">
      <div class="col-sm-3">
        <div class="size-text-14px semibold-weight text-ellipsis">
          <a :href="`/scout-company/matters/${matter.id}`" target="_blank">{{ matter.name || '&mdash;' }}</a>
        </div>

        <div class="pill-gray-text text-italic size-text-12px text-ellipsis tight-lines" v-if="matter.code">
          #{{ matter.code }}
        </div>

        <div class="size-text-12px tight-lines">
          <span class="semibold-weight">Manager:</span> {{ managerNames }}
        </div>
      </div>

      <div class="col-sm-9">
        <div class="row tight-columns vertical-center-not-xs">
          <div class="col-sm-2 top-10-xs">
            <div class="size-text-12px semibold-weight">
              Opened
            </div>

            {{ toDate(matter.openedOn) || '&mdash;' }}
          </div>

          <div class="col-sm-2 top-10-xs">
            <div class="size-text-12px semibold-weight">
              Closed
            </div>

            <div :class="{ 'missing-text': !matter.closedOn }">
              {{ matter.closedOn ? toDate(matter.closedOn) : 'Active' }}
            </div>
          </div>

          <div class="col-sm-2 wrap-word top-10-xs">
            <div class="size-text-12px semibold-weight">
              Total Spent
            </div>

            {{ formattedFee(matter.feeTotalInUsCents) }}
          </div>

          <div class="col-sm-3 top-10-xs">
            <div class="size-text-12px semibold-weight bottom-5 visible-xs-block">
              Internal Practice Area
            </div>

            <div class="tag-list bottom-0" v-if="matter.practiceArea">
              <div class="tag-list-item gray compact size-text-12px bottom-0">
                {{ matter.practiceArea }}
              </div>
            </div>

            <span v-else>&mdash;</span>
          </div>

          <div class="col-sm-3 top-20-xs">
            <div class="row tight-columns vertical-center" v-if="hasExpandableData || lawyersCount">
              <div class="col-xs-8 col-sm-7 col-lg-9">
                <rating-count-plate
                  :score="reviewScore"
                  :max-score="10"
                  :count="reviewCount"
                  :unit="'review'"
                  :line-break="true">
                </rating-count-plate>
              </div>

              <div class="col-xs-4 col-sm-5 col-lg-3 text-right">
                <a href="" class="nv-button-square-white" @click.prevent="toggleLawyers"><svg-icon name="caret-right" :class="['base-icon disclosure-icon', { 'expanded': isExpanded }]"></svg-icon></a>
              </div>
            </div>

            <div class="dark-gray-text text-right-not-xs" v-else>
              No Lawyer Data
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :class="['top-30', { 'bottom-5': !hasExpandableData }]" v-if="showExpandedData">
      <loading-section :name="'matterLawyers-' + matter.id">
        <div v-if="hasExpandableData">
          <div class="size-text-12px semibold-weight bottom-15 text-right">
            <a :href="`/scout-company/matters/${matter.id}`" class="dark-gray-link" target="_blank">View all reviews</a>
          </div>

          <div class="list-spacing-10" v-for="lawFirm in matter.matterLawFirms" :key="`matter-firm-${lawFirm.id}`">
            <matters-list-item-law-firm :law-firm="lawFirm"></matters-list-item-law-firm>
          </div>

          <div class="list-spacing-10" v-for="matterLawyer in matter.matterLawyers" :key="matterLawyer.id">
            <matters-list-item-lawyer :matter-lawyer="matterLawyer"></matters-list-item-lawyer>
          </div>
        </div>

        <div v-else class="gray-text size-text-16px text-center">
          Empty
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import { sortBy } from 'lodash';
import DateFilter from 'vue-app/shared/lib/date-filter';
import RatingCountPlate from 'vue-app/scout/shared/rating-count-plate.vue';
import MattersListItemLawyer from './matters-list-item-lawyer.vue';
import MattersListItemLawFirm from './matters-list-item-law-firm.vue';
import matterFeeFormatter from 'vue-app/shared/mixins/matter-fee-formatter.js';

export default {
  name: 'MattersListItem',

  components: {
    RatingCountPlate,
    MattersListItemLawyer,
    MattersListItemLawFirm
  },

  mixins: [matterFeeFormatter],

  props: {
    matter: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      isExpanded: false,
      lawyersAreLoaded: false
    };
  },

  computed: {
    hasExpandableData() {
      return this.hasLawyers || this.hasLawFirms;
    },

    hasLawyers() {
      return this.matter.matterLawyers && this.matter.matterLawyers.length > 0;
    },

    hasLawFirms() {
      return this.matter.matterLawFirms && this.matter.matterLawFirms.length > 0;
    },

    showExpandedData() {
      return this.isExpanded && this.hasExpandableData;
    },

    lawyersCount() {
      return this.matter.matterLawyersCount;
    },

    reviewCount() {
      return this.matter.reviewSummary.count;
    },

    reviewScore() {
      return this.matter.reviewSummary.score;
    },

    managersExist() {
      return this.matter.managerFullNames.length > 0;
    },

    managerNames() {
      return this.managersExist ? sortBy(this.matter.managerFullNames).join(', ') : '—';
    }
  },

  methods: {
    toDate: function (date) {
      return DateFilter.filterDate(date);
    },

    toggleLawyers: function () {
      this.isExpanded = !this.isExpanded;

      if (this.isExpanded && !this.lawyersAreLoaded) {
        this.$emit('load-lawyers', this.matter);
        this.lawyersAreLoaded = true;
      }
    }
  }
};
</script>
