<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <div class="bottom-30">
      <div class="bottom-30 normal-weight pill-gray-text">
        Use the fields below to notify additional individuals at the selected law firm about this RFP. Note that individuals notified via additional notifications must request access to the RFP from their law firm.
      </div>

      <div class="bottom-30">
        <div class="small-bold-header">
          Law Firm Name
        </div>

        <hr class="top-5">

        <div class=" semibold-weight">
          {{ firmName }}
        </div>
      </div>

      <div class="a-form">
        <div class="small-bold-header">
          Send Notification To:
        </div>

        <hr class="top-5">

        <div v-for="recipient in newAdditionalRecipients" class="field-group bottom-20">
          <rfq-contact-firm-additional-recipient
            :recipient="recipient"
            :on-remove="removeRecipient"
            :provisioned-emails="provisionedEmails"
            :additional-recipients="additionalRecipients">
          </rfq-contact-firm-additional-recipient>
        </div>

        <div class="semibold-weight blue-text ">
          <a href="" @click.prevent="addAdditionalRecipient">+ Additional Notification</a>
        </div>
      </div>
    </div>

    <div class="row tight-columns">
      <div class="col-sm-4">
        <button type="button" class="primary-btn-blue" :disabled="newAdditionalRecipients.length < 1" @click="handleSubmit(save)">{{ saveButtonText }}</button>
      </div>

      <!-- <div class="col-sm-4">
        <button type="button" class="primary-btn-blue" @click="handleSubmit(save)">{{ saveButtonText }}</button>
      </div> -->

      <div class="col-sm-3 top-20-xs">
        <button type="button" class="nv-button-white" @click="cancel">Cancel</button>
      </div>
    </div>
  </validation-observer>
</template>

<script>

import { ValidationObserver } from 'vee-validate';
import RfqContactFirmAdditionalRecipient from 'vue-app/scout/client/rfqs/contact-firm-additional-user.vue';

export default {
  name: 'RfqAdditionalRecipientsForm',

  components: {
    RfqContactFirmAdditionalRecipient,
    ValidationObserver
  },

  props: {
    firmName: {
      type: String,
      required: true
    },

    onCancel: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    provisionedEmails: {
      type: Array,
      default: () => []
    },

    additionalRecipients: {
      type: Array,
      default: () => []
    },

    saveButtonText: {
      type: String,
      default: 'Send'
    }
  },

  data() {
    return {
      newAdditionalRecipients: this.defaultAdditionalUsersState()
    };
  },

  methods: {
    save() {
      this.onSave(this.newAdditionalRecipients).then(() => {
        this.reset();
      });
    },

    cancel() {
      this.reset();
      this.onCancel();
    },

    reset() {
      this.$refs.observer.reset();
      this.newAdditionalRecipients = this.defaultAdditionalUsersState();
    },

    removeRecipient(recipient) {
      const index = this.newAdditionalRecipients.indexOf(recipient);

      if (index > -1) {
        this.newAdditionalRecipients.splice(index, 1);
      }
    },

    defaultAdditionalUsersState() {
      return [this.emptyUser()];
    },

    addAdditionalRecipient() {
      this.newAdditionalRecipients.push(this.emptyUser());
    },

    emptyUser() {
      return {
        firstName: null,
        lastName: null,
        email: null
      };
    }
  }
};
</script>
