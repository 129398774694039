<template>
  <priori-modal ref="contactFirmModal" modal-id="contact-firm-modal" modal-size="md" :title="modalTitle" @on-modal-close="reset">
    <template #modal-trigger="{ openModal }">
      <button class="gap-10 pseudo-link-gray semibold-weight size-text-12px vertical-center" @click="openModal"><svg-icon name="clients" class="base-icon"></svg-icon><span class="underlined">View Recipients</span></button>
    </template>

    <div v-if="showAddRecipientsForm">
      <rfq-recipients-list
        :firm-name="firmName"
        :provisioned-lawyers="provisionedLawyers"
        :additional-recipients="additionalRecipients"
        :on-add-recipients="toggleAddRecipientsForm">
      </rfq-recipients-list>
      <div class="row top-30">
        <div class="col-sm-3">
          <button type="button" class="nv-button-white" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
    <div v-else>
      <rfq-additional-recipients-form
        :firm-name="firmName"
        :provisioned-emails="provisionedEmails"
        :additional-recipients="additionalRecipients"
        :on-cancel="backToRecipientList"
        :on-save="save">
      </rfq-additional-recipients-form>
    </div>
  </priori-modal>
</template>

<script>

import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle.js';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import RfqRecipientsList from 'vue-app/scout/client/rfqs/recipients-list.vue';
import RfqAdditionalRecipientsForm from 'vue-app/scout/client/rfqs/additional-recipients-form.vue';
export default {
  name: 'RfqContactFirmModal',

  components: {
    PrioriModal,
    RfqRecipientsList,
    SvgIcon,
    RfqAdditionalRecipientsForm
  },

  mixins: [
    modalToggle
  ],

  props: {
    firmName: {
      type: String,
      required: true
    },

    provisionedLawyers: {
      type: Array,
      required: true
    },

    additionalRecipients: {
      type: Array,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      showAddRecipientsForm: true
    };
  },

  computed: {
    hasLawyers() {
      return this.provisionedLawyers.length > 0;
    },

    modalTitle() {
      return this.showAddRecipientsForm ? 'Contact Law Firm' : 'Send Additional RFP Notification';
    },

    provisionedEmails() {
      return this.provisionedLawyers.map(l => l.email);
    }
  },

  methods: {
    toggleAddRecipientsForm() {
      this.showAddRecipientsForm = !this.showAddRecipientsForm;
    },

    closeModal() {
      this.$refs.contactFirmModal.closeModal();
    },

    backToRecipientList() {
      this.reset();
      this.showAddRecipientsForm = true;
    },

    save(newAdditionalRecipients) {
      this.onSave(newAdditionalRecipients).then(() => {
        this.reset();
        this.closeModal();
      });
    },

    reset() {
      this.showAddRecipientsForm = true;
    }
  }
};
</script>
