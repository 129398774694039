/* eslint vue/component-definition-name-casing: "off" */

import Vue from 'vue';
import vueComponents from './vue-components';

/***********************/
/*  SHARED COMPONENTS  */
/***********************/

import Notification                     from 'vue-app/shared/components/notification.vue';
import TwoFactorAuthenticationSettings  from 'vue-app/shared/components/two-factor-authentication/two-factor-authentication-settings.vue';
import TwoFactorSetup                   from 'vue-app/shared/components/two-factor-authentication/two-factor-setup.vue';
import OtpLocked                        from 'vue-app/shared/components/two-factor-authentication/otp-locked.vue';
import OtpAttempt                       from 'vue-app/shared/components/two-factor-authentication/otp-attempt.vue';
import InterfaceNote                    from 'vue-app/shared/components/interface-note.vue';
import MultiSelectDropdown              from 'vue-app/shared/components/multi-select-dropdown.vue';
import DropdownSelectSections           from 'vue-app/shared/components/dropdown-select-sections.vue';
import DropdownSelect                   from 'vue-app/shared/components/dropdown-select.vue';
import PasswordSetupForm                from 'vue-app/shared/components/password-setup-form.vue';
import ImpersonationBubble              from 'vue-app/shared/components/impersonation-bubble.vue';
import PrioriPagination                 from 'vue-app/shared/components/priori-pagination.vue';
import SendMessageModal                 from 'vue-app/shared/components/send-message-modal.vue';
import SortSelect                       from 'vue-app/shared/components/sort-select.vue';
import SvgIcon                          from 'vue-app/shared/components/svg-icon.vue';
import DynamicOrderArrows               from 'vue-app/shared/components/dynamic-order-arrows.vue';
import TextareaWithUndo                 from 'vue-app/shared/components/textarea-with-undo.vue';
import ToggleFilter                     from 'vue-app/shared/components/toggle-filter.vue';
import TypeaheadVertical                from 'vue-app/shared/components/typeahead-vertical.vue';

/************************/
/*  MARKETPLACE LAWYER  */
/************************/

import LawyerQuickActions               from 'vue-app/marketplace/lawyer/dashboard/lawyer-quick-actions.vue';
import LawyerOpportunityApplications    from 'vue-app/marketplace/lawyer/dashboard/opportunity-applications.vue';
import LawyerPreferencesWidget          from 'vue-app/marketplace/lawyer/dashboard/lawyer-preferences-widget.vue';
import LawyerReminders                  from 'vue-app/marketplace/lawyer/dashboard/lawyer-reminders.vue';
import RateForm                         from 'vue-app/marketplace/lawyer/shared/rate-form.vue';
import ClientRequests                   from 'vue-app/marketplace/lawyer/dashboard/client-requests.vue';
import LawyerRecentProjects             from 'vue-app/marketplace/lawyer/dashboard/recent-projects.vue';
import DashboardTotals                  from 'vue-app/marketplace/lawyer/dashboard/dashboard-totals.vue';
import MarketplaceLawyerSettingsRoot    from 'vue-app/marketplace/lawyer/settings/root.vue';
import ProjectRatesTable                from 'vue-app/marketplace/lawyer/pricing-data/project-rates-table.vue';
import TopPracticesTable                from 'vue-app/marketplace/lawyer/pricing-data/top-practices-table.vue';
import PrioriRatesTable                 from 'vue-app/marketplace/lawyer/pricing-data/priori-rates-table.vue';
import VolumeWorkRatesTable             from 'vue-app/marketplace/lawyer/pricing-data/volume-work-rates-table.vue';
import LawyersShare                     from 'vue-app/marketplace/lawyer/documents/lawyers-share.vue';
import MarketplaceLawyerFirmsShow       from 'vue-app/marketplace/lawyer/firms/show.vue';

/************************/
/*  MARKETPLACE SHARED  */
/************************/

import LawyerEmploymentHistory          from 'vue-app/marketplace/shared/lawyers/lawyer-employment-history.vue';
import LawyerProfileSidebar             from 'vue-app/marketplace/shared/lawyers/lawyer-profile-sidebar.vue';
import LawyerProfile                    from 'vue-app/marketplace/shared/lawyers/lawyer-profile.vue';
import LawyerTestimonials               from 'vue-app/marketplace/shared/lawyers/lawyer-testimonials.vue';
import LawyerBio                        from 'vue-app/marketplace/shared/lawyers/lawyer-bio.vue';
import CallSchedule                     from 'vue-app/marketplace/shared/call-schedule/call-schedule.vue';
import AppointmentsNew                  from 'vue-app/marketplace/shared/appointments/scheduling.vue';
import AppointmentConfirmation          from 'vue-app/marketplace/shared/appointments/scheduling-confirmation.vue';
import ChooseTime                       from 'vue-app/marketplace/shared/appointments/choose-time.vue';
import ChooseTimeConfirmation           from 'vue-app/marketplace/shared/appointments/choose-time-confirmation.vue';
import EngagementLetterModal            from 'vue-app/marketplace/shared/engagement-letter-modal.vue';
import RfpFullFormStatic                from 'vue-app/marketplace/shared/requests/rfp-full-form-static.vue';
import ReviewLawyerForm                 from 'vue-app/marketplace/shared/review-lawyer-form.vue';

/******************/
/*  SCOUT CLIENT  */
/******************/

import MatterGraphics                 from 'vue-app/scout/client/law-firms/matter-graphics.vue';
import RecentlyViewed                 from 'vue-app/scout/client/searches/recently-viewed.vue';
import ScoutClientDashboardIndex      from 'vue-app/scout/client/dashboard/index.vue';
import FirmNotesAndDocuments          from 'vue-app/scout/client/law-firms/firm-notes-and-documents.vue';
import MattersList                    from 'vue-app/scout/client/matters/matters-list.vue';
import ReviewsAverageScore            from 'vue-app/scout/client/shared/reviews-average-score.vue';
import MatterLawyer                   from 'vue-app/scout/client/matters/matter-lawyer.vue';
import GhostFirmDetails               from 'vue-app/scout/client/law-firms/ghost-firm-details.vue';
import ReviewDetails                  from 'vue-app/scout/client/reviews/review-details.vue';
import ScoutClientLawyerShow          from 'vue-app/scout/client/lawyers/show.vue';
import FirmOverview                   from 'vue-app/scout/client/law-firms/firm-overview.vue';
import FirmReviews                    from 'vue-app/scout/client/law-firms/firm-reviews.vue';
import FirmInvoiceTimelinessBreakdown from 'vue-app/scout/client/law-firms/firm-invoice-timeliness-breakdown.vue';
import FirmRelationshipPartners       from 'vue-app/scout/client/law-firms/firm-relationship-partners.vue';
import ScoutReviewSuccessModal        from 'vue-app/scout/client/reviews/success-modal.vue';
import ScoutSuccessListModal          from 'vue-app/scout/client/searches/success-list-modal.vue';
import ScoutFirmCard                  from 'vue-app/scout/client/reviews/firm-card.vue';
import LawyerReviewShow               from 'vue-app/scout/client/reviews/lawyer-review-show.vue';
import LawFirmReviewShow              from 'vue-app/scout/client/reviews/law-firm-review-show.vue';
import LawFirmReviewForm              from 'vue-app/scout/client/reviews/law-firm-review-form.vue';
import LawFirmReviewNew               from 'vue-app/scout/client/reviews/law-firm-review-new.vue';
import UsageAnalyticsIndex            from 'vue-app/scout/client/usage-analytics/usage-analytics-index.vue';
import FirmList                       from 'vue-app/scout/client/law-firms/firm-list.vue';
import AddFirmModal                   from 'vue-app/scout/client/law-firms/add-firm-modal.vue';
import LawyersList                    from 'vue-app/scout/client/reviews/lawyers-list.vue';
import LawFirmsList                   from 'vue-app/scout/client/reviews/law-firms-list.vue';
import DraftsList                     from 'vue-app/scout/client/reviews/drafts-list.vue';
import ReviewFormGuideModal           from 'vue-app/scout/client/reviews/review-form-guide-modal.vue';
import QuestionYesNoSelector          from 'vue-app/scout/client/reviews/question-yes-no-selector.vue';
import MatterLawFirm                  from 'vue-app/scout/client/matters/matter-law-firm.vue';
import FilterActivityDate             from 'vue-app/scout/client/matters/filter-activity-date.vue';
import MattersListFilters             from 'vue-app/scout/client/matters/matters-list-filters.vue';
import ReviewReminders                from 'vue-app/scout/client/reviews/review-reminders.vue';
import ClientMatterTitlebar           from 'vue-app/scout/client/matters/client-matter-titlebar.vue';
import ClientRfqIndex                 from 'vue-app/scout/client/rfqs/index.vue';
import ClientRfqShow                  from 'vue-app/scout/client/rfqs/show.vue';
import ClientRfqComparison            from 'vue-app/scout/client/rfqs/comparison.vue';
import ClientRfqCreate                from 'vue-app/scout/client/rfqs/create.vue';
import ListFirm                       from 'vue-app/scout/client/lists/list-firm.vue';
import SearchFirm                     from 'vue-app/scout/client/searches/search-firm.vue';
import SearchLawyer                   from 'vue-app/scout/client/searches/search-lawyer.vue';
import FirmLawyer                     from 'vue-app/scout/client/law-firms/firm-lawyer.vue';
import ScoutClientTeamsIndex          from 'vue-app/scout/client/teams/index.vue';
import ScoutClientSettingsIndex       from 'vue-app/scout/client/settings/index.vue';
import ScoutClientSettingsUsersIndex  from 'vue-app/scout/client/settings/users/index.vue';
import ScoutClientSettingsRfqTemplatesIndex from 'vue-app/scout/client/settings/rfqs/templates/index.vue';
import ScoutClientSettingsRfqTemplatesShow  from 'vue-app/scout/client/settings/rfqs/templates/show.vue';
import ScoutClientSettingsRfqTemplatesNew   from 'vue-app/scout/client/settings/rfqs/templates/new.vue';
import ScoutClientAccountIndex        from 'vue-app/scout/client/account/index.vue';

/****************/
/*  SCOUT FIRM  */
/****************/

import ScoutFirmDashboardIndex     from 'vue-app/scout/firm/dashboard/index.vue';
import ScoutFirmAccountIndex       from 'vue-app/scout/firm/account/index.vue';

import FirmClientMatters           from 'vue-app/scout/firm/clients/firm-client-matters.vue';
import ScoutFirmLawyerShow         from 'vue-app/scout/firm/lawyers/show.vue';
import FirmClientLists             from 'vue-app/scout/firm/clients/firm-client-lists.vue';
import FirmClientCreateListForm    from 'vue-app/scout/firm/clients/firm-client-create-list-form.vue';
import ScoutFirmSettingsIndex      from 'vue-app/scout/firm/settings/index.vue';
import ScoutFirmUsers              from 'vue-app/scout/firm/settings/users.vue';
import DataLocksTable              from 'vue-app/scout/firm/data-locks-table.vue';
import ScoutProfileEditor          from 'vue-app/scout/firm/base-profile/scout-profile-editor.vue';
import RFQLawFirmQuotesIndex       from 'vue-app/scout/firm/rfqs/index.vue';
import RFQLawFirmQuotesIndexTable  from 'vue-app/scout/firm/rfqs/rfq-law-firm-quotes-index-table.vue';
import RFQLawFirmQuotesShow        from 'vue-app/scout/firm/rfqs/show.vue';
import ProfileDataPermission       from 'vue-app/scout/firm/profile-data-permission/profile-data-permission.vue';
import SearchLawyerGeneral         from 'vue-app/scout/firm/searches/search-lawyer-general.vue';
import SearchLawyerScoped          from 'vue-app/scout/firm/searches/search-lawyer-scoped.vue';

/******************/
/*  SCOUT SHARED  */
/******************/

import FavoriteButton               from 'vue-app/scout/shared/favorite-button.vue';
import ListHeaderDetails            from 'vue-app/scout/shared/lists/header-details.vue';
import ScoutLawyerCard              from 'vue-app/scout/shared/lawyers/lawyer-card.vue';
import UploadModal                  from 'vue-app/scout/shared/uploads/upload-modal.vue';
import UploadList                   from 'vue-app/scout/shared/uploads/upload-list.vue';
import FirmProfile                  from 'vue-app/scout/shared/law-firms/firm-profile.vue';
import StatusBox                    from 'vue-app/scout/shared/status-box.vue';
import UploadErrorItem              from 'vue-app/scout/shared/uploads/upload-error-item.vue';
import FirmRepresentativeMatters    from 'vue-app/scout/shared/law-firms/firm-representative-matters.vue';
import PerformanceScoreWithTopLabel from 'vue-app/scout/shared/performance-score-with-top-label.vue';
import BudgetTracker                from 'vue-app/scout/shared/budget-tracker';
import LawyerDiversityInclusion     from 'vue-app/scout/shared/lawyers/lawyer-diversity-inclusion.vue';
import DataDisclaimer               from 'vue-app/scout/shared/data-disclaimer.vue';
import ProfilePhoto                 from 'vue-app/scout/shared/profile-photo.vue';
import LawyerNamePlate              from 'vue-app/scout/shared/lawyer-name-plate.vue';
import FirmNamePlate                from 'vue-app/scout/shared/firm-name-plate.vue';
import DataDisclaimerPanel          from 'vue-app/scout/shared/data-disclaimer-panel.vue';
import RatingCircle                 from 'vue-app/scout/shared/rating-circle.vue';
import CollapsableScoutTagsList     from 'vue-app/scout/shared/scout-tags/collapsable-scout-tags-list.vue';
import ScoutTagsListWithModal       from 'vue-app/scout/shared/scout-tags/scout-tags-list-with-modal.vue';
import FormManageList               from 'vue-app/scout/shared/form-manage-list.vue';
import AddToListButton              from 'vue-app/scout/shared/lists/add-to-list-button.vue';
import ListLawyer                   from 'vue-app/scout/shared/lists/list-lawyer.vue';
import MatchTag                     from 'vue-app/scout/shared/match-tag.vue';
import SearchFilter                 from 'vue-app/scout/shared/searches/search-filter.vue';

/************************/
/*  FEATURE LAB CLIENT  */
/************************/

import TermsOfService             from 'vue-app/feature-lab/client/terms-of-service/terms-of-service.vue';

/***********/
/*  ADMIN  */
/***********/

import AdminSettings                   from 'vue-app/admin/settings/index.vue';
import LawFirmContact                  from 'vue-app/admin/law-firms/law-firm-contact.vue';
import LawFirmClearDataModal           from 'vue-app/admin/law-firms/law-firm-clear-data-modal.vue';
import LawFirmUsers                    from 'vue-app/admin/law-firms/law-firm-users.vue';
import WorkspaceProductStatus          from 'vue-app/admin/workspaces/workspace-product-status.vue';
import FirmScoutStatus                 from 'vue-app/admin/law-firms/firm-scout-status.vue';
import ClientAccountTitlebar           from 'vue-app/admin/clients/client-account-titlebar.vue';
import LawFirmTitlebar                 from 'vue-app/admin/law-firms/law-firm-titlebar.vue';
import AdminWorkspaceUsers             from 'vue-app/admin/clients/admin-workspace-users.vue';
import AdminDashboard                  from 'vue-app/admin/dashboard/index.vue';
import AdminLawyerLeads                from 'vue-app/admin/lawyer-leads/index.vue';
import ClientCredits                   from 'vue-app/admin/clients/client-credits.vue';
import AccountEmployment               from 'vue-app/admin/lawyers/account-employment.vue';
import ImpersonateModal                from 'vue-app/admin/impersonate-modal.vue';
import AdminAppointmentsTable          from 'vue-app/admin/appointments/appointments-table.vue';
import AdminAppointmentsShow           from 'vue-app/admin/appointments/show.vue';
import AccountFirm                     from 'vue-app/admin/lawyers/account-firm.vue';
import AccountStatus                   from 'vue-app/admin/lawyers/account-status.vue';
import AccountList                     from 'vue-app/admin/lawyers/account-list.vue';
import AccountPermissions              from 'vue-app/admin/lawyers/account-permissions.vue';
import AdminLinks                      from 'vue-app/admin/toolbox/admin-links.vue';
import AdminInquiries                  from 'vue-app/admin/toolbox/admin-inquiries.vue';
import ScreeningTerms                  from 'vue-app/admin/toolbox/screening-terms.vue';
import MatterStatus                    from 'vue-app/admin/matters/matter-status.vue';
import ReferenceDetail                 from 'vue-app/admin/lawyers/reference-detail.vue';
import MatterTeamMembers               from 'vue-app/admin/matters/matter-team-members.vue';
import MatterLegalArrangementRider     from 'vue-app/admin/matters/matter-legal-arrangement-rider.vue';
import MatterClient                    from 'vue-app/admin/matters/matter-client.vue';
import ClientAccountStatus             from 'vue-app/admin/clients/client-account-status.vue';
import AccountMatching                 from 'vue-app/admin/lawyers/account-matching.vue';
import MatchingItem                    from 'vue-app/admin/matching/matching-item.vue';
import MatterInvoiceSummary            from 'vue-app/admin/matters/matter-invoice-summary.vue';
import MatterRates                     from 'vue-app/admin/matters/matter-rates.vue';
import MatterLedesDocuments            from 'vue-app/admin/matters/matter-ledes-documents.vue';
import MatterListLawyers               from 'vue-app/admin/matters/matter-list-lawyers.vue';
import AccountClientFeedback           from 'vue-app/admin/lawyers/account-client-feedback.vue';
import MatterTitlebar                  from 'vue-app/admin/matters/matter-titlebar.vue';
import AccountProficiency              from 'vue-app/admin/lawyers/account-proficiency.vue';
import LawyerListItem                  from 'vue-app/admin/lawyers/lawyer-list-item.vue';
import ClientListItem                  from 'vue-app/admin/clients/client-list-item.vue';
import WorkspaceListItem               from 'vue-app/admin/workspaces/workspace-list-item.vue';
import LawFirmListItem                 from 'vue-app/admin/law-firms/law-firm-list-item.vue';
import ScoutLawyerListItem             from 'vue-app/admin/scout-lawyers/scout-lawyer-list-item.vue';
import AccountTitlebar                 from 'vue-app/admin/lawyers/account-titlebar.vue';
import SearchVettingTags               from 'vue-app/admin/search/vetting-tags.vue';
import RequestBudget                   from 'vue-app/admin/requests/request-budget.vue';
import RequestReferralInfo             from 'vue-app/admin/requests/request-referral-info.vue';
import RequestInvitations              from 'vue-app/admin/requests/request-invitations.vue';
import RfqProjectDetails               from 'vue-app/admin/requests/rfq-project-details.vue';
import RfqAnonymizedProjectDescription from 'vue-app/admin/requests/rfq-anonymized-project-description.vue';
import RfqRecipientQuestions           from 'vue-app/admin/requests/rfq-recipient-questions.vue';
import BillingNoteListItem             from 'vue-app/admin/billing-notes/billing-note-list-item.vue';
import ProposalClientActivity          from 'vue-app/admin/requests/proposal-client-activity.vue';
import ProposalAdminActivity           from 'vue-app/admin/requests/proposal-admin-activity.vue';
import ProposalBioEditor               from 'vue-app/admin/requests/proposal-bio-editor.vue';
import ProposalLawyerSections          from 'vue-app/admin/requests/proposal-lawyer-sections.vue';
import ProposalFirmSections            from 'vue-app/admin/requests/proposal-firm-sections.vue';
import ProposalRateCalculator          from 'vue-app/admin/requests/proposal-rate-calculator.vue';
import ProposalRecipients              from 'vue-app/admin/requests/proposal-recipients.vue';
import WorkspaceTitlebar               from 'vue-app/admin/workspaces/workspace-titlebar.vue';
import WorkspaceOptions                from 'vue-app/admin/workspaces/workspace-options.vue';
import RequestDetails                  from 'vue-app/admin/requests/request-details.vue';
import RequestQualifications           from 'vue-app/admin/requests/request-qualifications.vue';
import RequestAdmin                    from 'vue-app/admin/requests/request-admin.vue';
import RequestQuotes                   from 'vue-app/admin/requests/request-quotes.vue';
import RequestTitlebar                 from 'vue-app/admin/requests/request-titlebar.vue';
import TrackersIndex                   from 'vue-app/admin/requests/trackers-index.vue';
import NewCredit                       from 'vue-app/admin/clients/new-credit.vue';
import LeadDetails                     from 'vue-app/admin/lawyer-leads/lead-details.vue';
import LeadTitlebar                    from 'vue-app/admin/lawyer-leads/lead-titlebar.vue';
import StatusKeys                      from 'vue-app/admin/shared/status-keys.vue';
import SearchSort                      from 'vue-app/admin/shared/search-sort.vue';
import TableSort                       from 'vue-app/admin/shared/table-sort.vue';
import SearchPagination                from 'vue-app/admin/shared/search-pagination.vue';
import AdminClientAccountInfo          from 'vue-app/admin/clients/client-account-info.vue';
import AdminWorkspaceInfo              from 'vue-app/admin/workspaces/workspace-info.vue';
import WorkspaceBillingNote            from 'vue-app/admin/workspaces/workspace-billing-note.vue';
import LeadStatus                      from 'vue-app/admin/lawyer-leads/lead-status.vue';
import SearchHighlights                from 'vue-app/admin/search/search-highlights.vue';
import AdminSavedSearchesIndex         from 'vue-app/admin/saved-searches/index.vue';
import RequestList                     from 'vue-app/admin/requests/request-list.vue';
import MatterPaymentSetup              from 'vue-app/admin/matters/matter-payment-setup.vue';
import AdminSavedSearchModal           from 'vue-app/admin/saved-searches/saved-search-modal.vue';
import AdminSearchFilterValues         from 'vue-app/admin/shared/admin-search-filter-values.vue';
import AdminProposalsShow              from 'vue-app/marketplace/client/proposals/show.vue';
import OpportunityContainer            from 'vue-app/admin/opportunities/container.vue';

/************/
/*  PUBLIC  */
/************/

import LawyerOpportunitiesIndex from 'vue-app/public/opportunities/index.vue';
import LawyerOpportunitiesShow  from 'vue-app/public/opportunities/show.vue';
import ClientSignUpForm         from 'vue-app/public/client-sign-up-form.vue';
import PasswordResetForm        from 'vue-app/public/password-reset-form.vue';
import InvitedClientSignUpForm  from 'vue-app/public/invited-client-sign-up-form.vue';
import PublicProposalsShow      from 'vue-app/marketplace/client/proposals/show.vue';

vueComponents
.directive('ngvClientMatterTitlebar', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matter-titlebar', ClientMatterTitlebar));
}])

.directive('ngvClientRfqIndex', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('client-rfq-index', ClientRfqIndex));
}])

.directive('ngvClientRfqShow', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('client-rfq-show', ClientRfqShow));
}])

.directive('ngvClientRfqComparison', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('client-rfq-comparison', ClientRfqComparison));
}])

.directive('ngvRfqLawFirmQuotesIndex', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('rfq-law-firm-quotes-index', RFQLawFirmQuotesIndex));
}])

.directive('ngvRfqLawFirmQuotesIndexTable', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('rfq-law-firm-quotes-index-table', RFQLawFirmQuotesIndexTable));
}])

.directive('ngvClientRfqCreate', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('client-rfq-create', ClientRfqCreate));
}])

.directive('ngvRfqLawFirmQuotesShow', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('rfq-law-firm-quotes-show', RFQLawFirmQuotesShow));
}])

.directive('ngvProfileDataPermission', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('profile-data-permission', ProfileDataPermission));
}])

.directive('ngvSearchLawyerGeneral', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('search-lawyer-general', SearchLawyerGeneral));
}])

.directive('ngvSearchLawyerScoped', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('search-lawyer-scoped', SearchLawyerScoped));
}])

.directive('ngvFormManageList', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('form-manage-list', FormManageList));
}])

.directive('ngvAddToListButton', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('add-to-list-button', AddToListButton));
}])

.directive('ngvListLawyer', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('list-lawyer', ListLawyer));
}])

.directive('ngvMatchTag', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('match-tag', MatchTag));
}])

.directive('ngvSearchFilter', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('search-filter', SearchFilter));
}])

.directive('ngvListFirm', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('list-firm', ListFirm));
}])

.directive('ngvSearchLawyer', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('search-lawyer', SearchLawyer));
}])

.directive('ngvFirmLawyer', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('firm-lawyer', FirmLawyer));
}])

.directive('ngvScoutClientTeamsIndex', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-client-teams-index', ScoutClientTeamsIndex));
}])

.directive('ngvScoutClientSettingsIndex', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-client-settings-index', ScoutClientSettingsIndex));
}])

.directive('ngvScoutClientSettingsUsersIndex', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-client-settings-users-index', ScoutClientSettingsUsersIndex));
}])

.directive('ngvScoutClientSettingsRfqTemplatesIndex', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-client-settings-rfq-templates-index', ScoutClientSettingsRfqTemplatesIndex));
}])

.directive('ngvScoutClientSettingsRfqTemplatesShow', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-client-settings-rfq-templates-show', ScoutClientSettingsRfqTemplatesShow));
}])

.directive('ngvScoutClientSettingsRfqTemplatesNew', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-client-settings-rfq-templates-new', ScoutClientSettingsRfqTemplatesNew));
}])

.directive('ngvScoutClientAccountIndex', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-client-account-index', ScoutClientAccountIndex));
}])

.directive('ngvSearchFirm', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('search-firm', SearchFirm));
}])

.directive('ngvScoutLawyerCard', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-lawyer-card', ScoutLawyerCard));
}])

.directive('ngvScoutFirmCard', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-firm-card', ScoutFirmCard));
}])

.directive('ngvMatterGraphics', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matter-graphics', MatterGraphics));
}])

.directive('ngvAppointmentConfirmation', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('appointment-confirmation', AppointmentConfirmation));
}])

.directive('ngvLawyerEmploymentHistory', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawyer-employment-history', LawyerEmploymentHistory));
}])

.directive('ngvLawyerProfileSidebar', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawyer-profile-sidebar', LawyerProfileSidebar));
}])

.directive('ngvLawyerTestimonials', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawyer-testimonials', LawyerTestimonials));
}])

.directive('ngvLawyerBio', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawyer-bio', LawyerBio));
}])

.directive('ngvLawyerProfile', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawyer-profile', LawyerProfile));
}])

.directive('ngvFavoriteButton', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('favorite-button', FavoriteButton));
}])

.directive('ngvListHeaderDetails', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('list-header-details', ListHeaderDetails));
}])

.directive('ngvNotification', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('notification', Notification));
}])

.directive('ngvCallSchedule', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('call-schedule', CallSchedule));
}])

.directive('ngvRecentlyViewed', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('recently-viewed', RecentlyViewed));
}])

.directive('ngvScoutClientDashboardIndex', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-client-dashboard-index', ScoutClientDashboardIndex));
}])

.directive('ngvLawyerQuickActions', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawyer-quick-actions', LawyerQuickActions));
}])

.directive('ngvOpportunityApplications', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('opportunity-applications', LawyerOpportunityApplications));
}])

.directive('ngvLawyerPreferencesWidget', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawyer-preferences-widget', LawyerPreferencesWidget));
}])

.directive('ngvLawyerReminders', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawyer-reminders', LawyerReminders));
}])

.directive('ngvRateForm', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('rate-form', RateForm));
}])

.directive('ngvAdminSettings', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('admin-settings', AdminSettings));
}])

.directive('ngvTwoFactorAuthenticationSettings', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('two-factor-authentication-settings', TwoFactorAuthenticationSettings));
}])

.directive('ngvTwoFactorSetup', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('two-factor-setup', TwoFactorSetup));
}])

.directive('ngvOtpAttempt', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('otp-attempt', OtpAttempt));
}])

.directive('ngvOtpLocked', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('otp-locked', OtpLocked));
}])

.directive('ngvUploadList', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('uploadList', UploadList));
}])

.directive('ngvUploadModal', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('uploadModal', UploadModal));
}])

.directive('ngvFirmProfile', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('firm-profile', FirmProfile));
}])

.directive('ngvLawFirmContact', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawFirmContact', LawFirmContact));
}])

.directive('ngvLawFirmClearDataModal', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawFirmClearDataModal', LawFirmClearDataModal));
}])

.directive('ngvLawFirmUsers', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawFirmUsers', LawFirmUsers));
}])

.directive('ngvStatusBox', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('statusBox', StatusBox));
}])

.directive('ngvUploadErrorItem', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('uploadErrorItem', UploadErrorItem));
}])

.directive('ngvLawyerOpportunitiesIndex', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawyer-opportunities-index', LawyerOpportunitiesIndex));
}])

.directive('ngvLawyerOpportunitiesShow', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawyer-opportunities-show', LawyerOpportunitiesShow));
}])

.directive('ngvClientSignUpForm', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('client-sign-up-form', ClientSignUpForm));
}])

.directive('ngvPasswordResetForm', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('password-reset-form', PasswordResetForm));
}])

.directive('ngvClientRequests', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('client-requests', ClientRequests));
}])

.directive('ngvFirmRepresentativeMatters', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('firm-representative-matters', FirmRepresentativeMatters));
}])

.directive('ngvScoutFirmAccountIndex', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-firm-account-index', ScoutFirmAccountIndex));
}])

.directive('ngvFirmClientMatters', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('firm-client-matters', FirmClientMatters));
}])

.directive('ngvInterfaceNote', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('interfaceNote', InterfaceNote));
}])

.directive('ngvFirmNotesAndDocuments', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('firm-notes-and-documents', FirmNotesAndDocuments));
}])

.directive('ngvMattersList', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matters-list', MattersList));
}])

.directive('ngvReviewsAverageScore', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('reviews-average-score', ReviewsAverageScore));
}])

.directive('ngvMatterLawyer', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matter-lawyer', MatterLawyer));
}])

.directive('ngvPerformanceScoreWithTopLabel', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('performance-score-with-top-label', PerformanceScoreWithTopLabel));
}])

.directive('ngvBudgetTracker', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('budget-tracker', BudgetTracker));
}])

.directive('ngvScoutFirmDashboardIndex', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-firm-dashboard-index', ScoutFirmDashboardIndex));
}])

.directive('ngvGhostFirmDetails', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('ghost-firm-details', GhostFirmDetails));
}])

.directive('ngvReviewDetails', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('review-details', ReviewDetails));
}])

.directive('ngvScoutFirmLawyerShow', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-firm-lawyer-show', ScoutFirmLawyerShow));
}])

.directive('ngvScoutClientLawyerShow', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-client-lawyer-show', ScoutClientLawyerShow));
}])

.directive('ngvWorkspaceProductStatus', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('workspace-scout-status', WorkspaceProductStatus));
}])

.directive('ngvFirmScoutStatus', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('firm-scout-status', FirmScoutStatus));
}])

.directive('ngvClientAccountTitlebar', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('client-account-titlebar', ClientAccountTitlebar));
}])

.directive('ngvFirmClientLists', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('firm-client-lists', FirmClientLists));
}])

.directive('ngvFirmOverview', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('firm-overview', FirmOverview));
}])

.directive('ngvFirmReviews', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('firm-reviews', FirmReviews));
}])

.directive('ngvFirmInvoiceTimelinessBreakdown', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('firm-invoice-timeliness-breakdown', FirmInvoiceTimelinessBreakdown));
}])

.directive('ngvFirmRelationshipPartners', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('firm-relationship-partners', FirmRelationshipPartners));
}])

.directive('ngvFirmClientCreateListForm', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('firm-client-create-list-form', FirmClientCreateListForm));
}])

.directive('ngvMultiSelectDropdown', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('multi-select-dropdown', MultiSelectDropdown));
}])

.directive('ngvDropdownSelectSections', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('dropdown-select-sections', DropdownSelectSections));
}])

.directive('ngvDropdownSelect', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('dropdown-select', DropdownSelect));
}])

.directive('ngvPasswordSetupForm', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('password-setup-form', PasswordSetupForm));
}])

.directive('ngvScoutReviewSuccessModal', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-review-success-modal', ScoutReviewSuccessModal));
}])

.directive('ngvLawyerDiversityInclusion', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawyer-diversity-inclusion', LawyerDiversityInclusion));
}])

.directive('ngvScoutFirmSettingsIndex', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-firm-settings-index', ScoutFirmSettingsIndex));
}])

.directive('ngvScoutFirmUsers', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-firm-users', ScoutFirmUsers));
}])

.directive('ngvDataLocksTable', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('data-locks-table', DataLocksTable));
}])

.directive('ngvDataDisclaimer', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('data-disclaimer', DataDisclaimer));
}])

.directive('ngvProfilePhoto', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('profile-photo', ProfilePhoto));
}])

.directive('ngvLawyerNamePlate', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawyer-name-plate', LawyerNamePlate));
}])

.directive('ngvFirmNamePlate', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('firm-name-plate', FirmNamePlate));
}])

.directive('ngvDataDisclaimerPanel', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('data-disclaimer-panel', DataDisclaimerPanel));
}])

.directive('ngvLawFirmTitlebar', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('law-firm-titlebar', LawFirmTitlebar));
}])

.directive('ngvScoutProfileEditor', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-profile-editor', ScoutProfileEditor));
}])

.directive('ngvAdminWorkspaceUsers', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('admin-workspace-users', AdminWorkspaceUsers));
}])

.directive('ngvAdminDashboard', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('admin-dashboard', AdminDashboard));
}])

.directive('ngvAdminLawyerLeads', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('admin-lawyer-leads', AdminLawyerLeads));
}])

.directive('ngvClientCredits', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('client-credits', ClientCredits));
}])

.directive('ngvAccountEmployment', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('account-employment', AccountEmployment));
}])

.directive('ngvImpersonateModal', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('impersonate-modal', ImpersonateModal));
}])

.directive('ngvImpersonationBubble', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('impersonation-bubble', ImpersonationBubble));
}])

.directive('ngvAdminAppointmentsTable', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('admin-appointments-table', AdminAppointmentsTable));
}])

.directive('ngvAdminAppointmentsShow', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('admin-appointments-show', AdminAppointmentsShow));
}])

.directive('ngvAccountFirm', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('account-firm', AccountFirm));
}])

.directive('ngvAccountStatus', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('account-status', AccountStatus));
}])

.directive('ngvAccountList', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('account-list', AccountList));
}])

.directive('ngvAccountPermissions', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('account-permissions', AccountPermissions));
}])

.directive('ngvAdminLinks', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('admin-links', AdminLinks));
}])

.directive('ngvAdminInquiries', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('admin-inquiries', AdminInquiries));
}])

.directive('ngvScreeningTerms', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('screening-terms', ScreeningTerms));
}])

.directive('ngvMatterStatus', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matter-status', MatterStatus));
}])

.directive('ngvReferenceDetail', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('reference-detail', ReferenceDetail));
}])

.directive('ngvMatterTeamMembers', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matter-team-members', MatterTeamMembers));
}])

.directive('ngvMatterLegalArrangementRider', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matter-legal-arrangement-rider', MatterLegalArrangementRider));
}])

.directive('ngvMatterClient', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matter-client', MatterClient));
}])

.directive('ngvClientAccountStatus', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('client-account-status', ClientAccountStatus));
}])

.directive('ngvAccountMatching', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('account-matching', AccountMatching));
}])

.directive('ngvMatchingItem', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matching-item', MatchingItem));
}])

.directive('ngvMatterInvoiceSummary', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matter-invoice-summary', MatterInvoiceSummary));
}])

.directive('ngvMatterRates', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matter-rates', MatterRates));
}])

.directive('ngvMatterLedesDocuments', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matter-ledes-documents', MatterLedesDocuments));
}])

.directive('ngvMatterListLawyers', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matter-list-lawyers', MatterListLawyers));
}])

.directive('ngvAccountClientFeedback', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('account-client-feedback', AccountClientFeedback));
}])

.directive('ngvTypeaheadVertical', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('typeahead-vertical', TypeaheadVertical));
}])

.directive('ngvMatterTitlebar', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matter-titlebar', MatterTitlebar));
}])

.directive('ngvAccountProficiency', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('account-proficiency', AccountProficiency));
}])

.directive('ngvRecentProjects', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('recent-projects', LawyerRecentProjects));
}])

.directive('ngvScoutSuccessListModal', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-success-list-modal', ScoutSuccessListModal));
}])

.directive('ngvLawyerListItem', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawyer-list-item', LawyerListItem));
}])

.directive('ngvClientListItem', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('client-list-item', ClientListItem));
}])

.directive('ngvWorkspaceListItem', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('workspace-list-item', WorkspaceListItem));
}])

.directive('ngvLawFirmListItem', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('law-firm-list-item', LawFirmListItem));
}])

.directive('ngvScoutLawyerListItem', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-lawyer-list-item', ScoutLawyerListItem));
}])

.directive('ngvLawyerReviewShow', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawyer-review-show', LawyerReviewShow));
}])

.directive('ngvLawFirmReviewShow', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('law-firm-review-show', LawFirmReviewShow));
}])

.directive('ngvLawFirmReviewForm', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('law-firm-review-form', LawFirmReviewForm));
}])

.directive('ngvAccountTitlebar', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('account-titlebar', AccountTitlebar));
}])

.directive('ngvSearchVettingTags', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('search-vetting-tags', SearchVettingTags));
}])

.directive('ngvRequestBudget', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('request-budget', RequestBudget));
}])

.directive('ngvRequestReferralInfo', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('request-referral-info', RequestReferralInfo));
}])

.directive('ngvRequestInvitations', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('request-invitations', RequestInvitations));
}])

.directive('ngvRfqProjectDetails', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('rfq-project-details', RfqProjectDetails));
}])

.directive('ngvRfqAnonymizedProjectDescription', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('rfq-anonymized-project-description', RfqAnonymizedProjectDescription));
}])

.directive('ngvRfqRecipientQuestions', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('rfq-recipient-questions', RfqRecipientQuestions));
}])

.directive('ngvBillingNoteListItem', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('billing-note-list-item', BillingNoteListItem));
}])

.directive('ngvPagination', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('priori-pagination', PrioriPagination));
}])

.directive('ngvProposalClientActivity', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('proposal-client-activity', ProposalClientActivity));
}])

.directive('ngvProposalAdminActivity', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('proposal-admin-activity', ProposalAdminActivity));
}])

.directive('ngvProposalBioEditor', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('proposal-bio-editor', ProposalBioEditor));
}])

.directive('ngvProposalLawyerSections', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('proposal-lawyer-sections', ProposalLawyerSections));
}])

.directive('ngvProposalFirmSections', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('proposal-firm-sections', ProposalFirmSections));
}])

.directive('ngvProposalRateCalculator', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('proposal-rate-calculator', ProposalRateCalculator));
}])

.directive('ngvProposalRecipients', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('proposal-recipients', ProposalRecipients));
}])

.directive('ngvLawFirmReviewNew', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('law-firm-review-new', LawFirmReviewNew));
}])

.directive('ngvWorkspaceTitlebar', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('workspace-titlebar', WorkspaceTitlebar));
}])

.directive('ngvWorkspaceOptions', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('workspace-options', WorkspaceOptions));
}])

.directive('ngvRequestDetails', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('request-details', RequestDetails));
}])

.directive('ngvRequestQualifications', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('request-qualifications', RequestQualifications));
}])

.directive('ngvRequestAdmin', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('request-admin', RequestAdmin));
}])

.directive('ngvRequestQuotes', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('request-quotes', RequestQuotes));
}])

.directive('ngvRequestTitlebar', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('request-titlebar', RequestTitlebar));
}])

.directive('ngvTrackersIndex', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('trackers-index', TrackersIndex));
}])

.directive('ngvUsageAnalyticsIndex', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('usage-analytics-index', UsageAnalyticsIndex));
}])

.directive('ngvFirmList', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('firm-list', FirmList));
}])

.directive('ngvAddFirmModal', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('add-firm-modal', AddFirmModal));
}])

.directive('ngvLeadDetails', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lead-details', LeadDetails));
}])

.directive('ngvLeadTitlebar', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lead-titlebar', LeadTitlebar));
}])

.directive('ngvNewCredit', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('new-credit', NewCredit));
}])

.directive('ngvStatusKeys', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('status-keys', StatusKeys));
}])

.directive('ngvSearchSort', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('search-sort', SearchSort));
}])

.directive('ngvTableSort', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('table-sort', TableSort));
}])

.directive('ngvSearchPagination', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('search-pagination', SearchPagination));
}])

.directive('ngvAdminClientAccountInfo', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('admin-client-account-info', AdminClientAccountInfo));
}])

.directive('ngvAdminWorkspaceInfo', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('admin-workspace-info', AdminWorkspaceInfo));
}])

.directive('ngvRatingCircle', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('rating-circle', RatingCircle));
}])

.directive('ngvWorkspaceBillingNote', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('workspace-billing-note', WorkspaceBillingNote));
}])

.directive('ngvLawyersList', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawyers-list', LawyersList));
}])

.directive('ngvLeadStatus', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lead-status', LeadStatus));
}])

.directive('ngvLawFirmsList', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('law-firms-list', LawFirmsList));
}])

.directive('ngvSendMessageModal', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('send-message-modal', SendMessageModal));
}])

.directive('ngvSearchHighlights', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('search-highlights', SearchHighlights));
}])

.directive('ngvDraftsList', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('drafts-list', DraftsList));
}])

.directive('ngvReviewFormGuideModal', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('review-form-guide-modal', ReviewFormGuideModal));
}])

.directive('ngvAdminSavedSearchesIndex', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('admin-saved-searches-index', AdminSavedSearchesIndex));
}])

.directive('ngvRequestList', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('request-list', RequestList));
}])

.directive('ngvQuestionYesNoSelector', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('question-yes-no-selector', QuestionYesNoSelector));
}])

.directive('ngvAdminSavedSearchModal', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('admin-saved-search-modal', AdminSavedSearchModal));
}])

.directive('ngvScoutTagsListWithModal', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('scout-tags-list-with-modal', ScoutTagsListWithModal));
}])

.directive('ngvCollapsableScoutTagsList', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('collapsable-scout-tags-list', CollapsableScoutTagsList));
}])

.directive('ngvSvgIcon', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('svg-icon', SvgIcon));
}])

.directive('ngvMatterPaymentSetup', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matter-payment-setup', MatterPaymentSetup));
}])

.directive('ngvDashboardTotals', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('dashboard-totals', DashboardTotals));
}])

.directive('ngvMarketplaceLawyerSettingsRoot', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('marketplace-lawyer-settings-root', MarketplaceLawyerSettingsRoot));
}])

.directive('ngvProjectRatesTable', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('project-rates-table', ProjectRatesTable));
}])

.directive('ngvTopPracticesTable', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('top-practices-table', TopPracticesTable));
}])

.directive('ngvPrioriRatesTable', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('priori-rates-table', PrioriRatesTable));
}])

.directive('ngvVolumeWorkRatesTable', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('volume-work-rates-table', VolumeWorkRatesTable));
}])

.directive('ngvMatterLawFirm', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matter-law-firm', MatterLawFirm));
}])

.directive('ngvFilterActivityDate', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('filter-activity-date', FilterActivityDate));
}])

.directive('ngvMattersListFilters', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('matters-list-filters', MattersListFilters));
}])

.directive('ngvAdminSearchFilterValues', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('admin-search-filter-values', AdminSearchFilterValues));
}])

.directive('ngvAdminProposalsShow', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('admin-proposals-show', AdminProposalsShow));
}])

.directive('ngvOpportunityContainer', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('opportunity-container', OpportunityContainer));
}])

.directive('ngvReviewLawyerForm', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('review-lawyer-form', ReviewLawyerForm));
}])

.directive('ngvDynamicOrderArrows', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('dynamic-order-arrows', DynamicOrderArrows));
}])

.directive('ngvTextareaWithUndo', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('textarea-with-undo', TextareaWithUndo));
}])

.directive('ngvLawyersShare', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('lawyers-share', LawyersShare));
}])

.directive('ngvMarketplaceLawyerFirmsShow', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('marketplace-lawyer-firms-show', MarketplaceLawyerFirmsShow));
}])

.directive('ngvReviewReminders', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('review-reminders', ReviewReminders));
}])

.directive('ngvInvitedClientSignUpForm', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('invited-client-sign-up-form', InvitedClientSignUpForm));
}])

.directive('ngvPublicProposalsShow', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('public-proposals-show', PublicProposalsShow));
}])

.directive('ngvSortSelect', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('sort-select', SortSelect));
}])

.directive('ngvToggleFilter', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('toggle-filter', ToggleFilter));
}])

.directive('ngvAppointmentsNew', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('appointments-new', AppointmentsNew));
}])

.directive('ngvChooseTime', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('choose-time', ChooseTime));
}])

.directive('ngvChooseTimeConfirmation', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('choose-time-confirmation', ChooseTimeConfirmation));
}])

.directive('ngvEngagementLetterModal', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('engagement-letter-modal', EngagementLetterModal));
}])

.directive('ngvTermsOfService', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('terms-of-service', TermsOfService));
}])

.directive('ngvRfpFullFormStatic', ['createVueComponent', function (createVueComponent) {
  return createVueComponent(Vue.component('rfp-full-form-static', RfpFullFormStatic));
}]);
