<template>
  <div>
    <div class="text-center bold-weight bottom-20">
      <a :href="`/scout-company/rfps/${matter.scoutRfqId}/next-steps`">View RFP #{{ matter.scoutRfqId }}</a>
      <popover-tooltip placement="bottom" custom-class="tooltip-white">
        <svg-icon name="info" class="base-icon text-top"></svg-icon>
        <template #tooltip>
          If this RFP is incorrectly linked, you can replace or disconnect it <a class="bold-weight" :href="`scout-company/rfps/${matter.scoutRfqId}/next-steps`">here</a>.
        </template>
      </popover-tooltip>
    </div>

    <div class="row tight-columns">
      <div class="col-xs-4">
        <div class="d-flex flex-center flex-column label-container text-center">
          <div v-if="confirmedBudget > 0">
            <strong>Confirmed Budget</strong>
            <div>{{ formattedCurrency(confirmedBudget) }}</div>
          </div>
          <div v-else class="semibold-weight">
            <div><svg-icon name="exclamation" class="base-icon"></svg-icon></div>
            <div>Budget Not Available</div>
          </div>
        </div>
      </div>

      <div class="col-xs-1 label-container">
        <div v-if="overages > 0" class="t-bar-overages"></div>

        <div class="t-bar"></div>
      </div>

      <div class="col-xs-7">
        <popover-tooltip v-if="overages > 0" placement="right" custom-class="tooltip-white">
          <div class="bar-container">
            <div class="gold-bar"></div>
            <div class="blue-bar"></div>
          </div>

          <template #tooltip>
            <strong>Total Spend:</strong>
            <div>
              {{ formattedCurrency(totalSpend) }} ({{ totalSpendPercent.toFixed(2) }}%)
            </div>
          </template>
        </popover-tooltip>
        <div v-else class="bar-container">
          <popover-tooltip v-if="remainingSpendPercent > 0" placement="right" custom-class="tooltip-white">
            <div :class="['gray-bar', remainingSpendPercent === 100 ? 'unstacked' : 'stacked']"></div>
            <template v-if="confirmedBudget > 0" #tooltip>
              <strong>Remaining:</strong>
              <div>
                {{ formattedCurrency(remainingSpend) }} ({{ remainingSpendPercent.toFixed(2) }}%)
              </div>
            </template>
          </popover-tooltip>

          <popover-tooltip v-if="totalSpendPercent > 0" placement="right" custom-class="tooltip-white">
            <div class="blue-bar"></div>
            <template #tooltip>
              <strong>Total Spend:</strong>
              <div>
                {{ formattedCurrency(totalSpend) }} ({{ totalSpendPercent.toFixed(2) }}%)
              </div>
            </template>
          </popover-tooltip>
        </div>
      </div>
    </div>

    <div v-if="overages > 0" class="d-flex flex-center top-20">
      <div class="footer-container semibold-weight size-text-13px">
        <svg-icon name="exclamation" class="base-icon warning-icon"></svg-icon> Overage: <span class="bold-weight size-text-15px">{{ formattedCurrency(overages) }}</span>
      </div>
    </div>
    <div v-else-if="confirmedBudget <= 0" class="top-20 text-center size-text-14px">
      To track this budget, you will need to finalize the RFP budget in Next Steps.
      <div class="top-20"><a type="button" class="primary-btn-blue" :href="`scout-company/rfps/${rfq.id}/next-steps`">Go to next steps</a></div>
    </div>
    <div v-else class="d-flex flex-center top-20">
      <div class="top-20 footer-container">
        <div>Remaining Budget: <strong>{{ formattedCurrency(confirmedBudget - totalSpend) }}</strong></div>
      </div>
    </div>
  </div>
</template>

<script>

import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import PopoverTooltip from 'vue-app/shared/components/popover-tooltip.vue';
import currencyFilter from 'vue-app/shared/mixins/currency-filter.js';
export default {
  name: 'BudgetTracker',

  components: {
    SvgIcon,
    PopoverTooltip
  },

  mixins: [
    currencyFilter
  ],

  props: {
    matter: {
      type: Object,
      required: true
    },

    rfq: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      totalSpend: this.matter.feeTotalInUsCents / 100.00
    };
  },

  computed: {
    confirmedBudget() {
      return (this.rfq?.confirmedBudgetInUsCents || 0) / 100.00;
    },

    overages() {
      if (!this.rfq.confirmedBudgetInUsCents) {
        return 0;
      }

      return (this.matter.feeTotalInUsCents - this.rfq.confirmedBudgetInUsCents) / 100.00;
    },

    overagesRenderPercent() {
      if (this.overages <= 0) {
        return 0;
      }

      return 100.00 - this.totalSpendRenderPercent;
    },

    totalSpendPercent() {
      return (this.totalSpend / this.confirmedBudget) * 100.00;
    },

    totalSpendRenderPercent() {
      if (this.overages > 0) {
        return (this.confirmedBudget / this.totalSpend) * 100.00;
      }

      return (this.totalSpend / this.confirmedBudget) * 100.00;
    },

    remainingSpend() {
      return this.confirmedBudget - this.totalSpend;
    },

    remainingSpendPercent() {
      if (this.overages > 0) { return 0; }
      if (this.confirmedBudget <= 0) { return 100; }

      return 100.00 - this.totalSpendRenderPercent;
    },

    overagesRenderStr() {
      return `${this.overagesRenderPercent}%`;
    },

    remainingSpendRenderStr() {
      return `${this.remainingSpendPercent}%`;
    },

    tBarRenderStr() {
      return `${100 - this.overagesRenderPercent}%`;
    },

    totalSpendRenderStr() {
      return `${this.totalSpendRenderPercent}%`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "stylesheets/scout/variables";

  .label-container {
    height: 233px;
  }

  .bar-container {
    height: 233px;
    width: 56px;
  }

  .footer-container {
    padding: 10px 15px;
    border: 1px solid $k-gray;
    border-radius: $border-radius-base;
    background: $bg-color;
    text-align: center;
  }

  .t-bar-overages {
    height: v-bind(overagesRenderStr);;
    width: 10px;
  }

  .t-bar {
    height: v-bind(tBarRenderStr);
    width: 10px;
    border-top: 2px solid $k-dark-gray;
    border-bottom: 2px solid $k-dark-gray;
    background: linear-gradient($k-dark-gray, $k-dark-gray) no-repeat center/2px 100%;
  }

  .gold-bar {
    border-radius: 4px 4px 0 0;
    background-color: $yellow-7;
    width: 56px;
    height: v-bind(overagesRenderStr);
  }

  .gray-bar {
    background-color: $k-gray;
    width: 56px;
    height: v-bind(remainingSpendRenderStr);

    &.stacked {
      border-radius: 4px 4px 0 0;
    }

    &.unstacked {
      border-radius: $border-radius-base;
    }
  }

 .blue-bar {
   border-radius: 0 0 4px 4px;
   margin-top: 2px;
   background-color: $blue-6;
   width: 56px;
   height: calc(v-bind(totalSpendRenderStr) - 2px);
 }

  .remaining-bar {
    width: 56px;
    height: 200px;
  }

  .warning-icon {
    fill: $yellow-7;
  }
</style>
