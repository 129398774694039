<template>
  <div class="row">
    <div class="col-sm-8 col-md-7">
      <div>
        <div class="bold-weight bottom-5">
          Description of Work
        </div>

        <div>
          <span class="simple-text">{{ rfq.descriptionOfWork || '—' }}</span>
        </div>
      </div>

      <div class="top-20" v-if="hasCounterpartyDetails">
        <div class="bold-weight bottom-5">
          Any relevant opposing party/counterparty details, including counsel
        </div>

        <div>
          <span class="simple-text">{{ rfq.counterpartyDetails }}</span>
        </div>
      </div>

      <div class="top-20" v-if="hasEstimatedTimeline">
        <div class="bold-weight bottom-5">
          Estimated Timeline
        </div>

        <div>
          <span class="simple-text">{{ rfq.estimatedTimeline }}</span>
        </div>
      </div>

      <div class="top-20" v-if="hasPreferredRateStructure">
        <div class="bold-weight bottom-5">
          Preferred Rate Structure
        </div>

        <div>
          <span class="simple-text">{{ rfq.preferredRateStructure }}</span>
        </div>
      </div>

      <div class="top-20" v-if="hasAdditionalInformation">
        <div class="bold-weight bottom-5">
          Additional Information
        </div>

        <div>
          <span class="simple-text">{{ rfq.additionalInformation }}</span>
        </div>
      </div>

      <div class="top-20" v-if="hasSupportingDocuments">
        <div class="bold-weight bottom-5">
          Supporting Documents ({{ supportingDocuments.length }})
        </div>

        <ul class="compact">
          <li class="list-spacing-2" v-for="doc in supportingDocuments"><a :href="doc.url" class="bold-weight" target="_blank" rel="noopener">{{ doc.name }}</a></li>
        </ul>
      </div>

      <div class="top-40" v-if="hasSuggestedLawyers">
        <div class="size-text-15px bold-weight heading-underline bottom-10">
          Suggested Lawyers
        </div>

        <div class="bottom-15">
          {{ workspace.name }} has suggested these lawyers for this project.
        </div>

        <div v-for="option in orderedSuggestedLawyers" :key="option.id">
          <div class="top-10 bottom-10">
            <hr class="no-margin">
          </div>

          <div class="row tight-columns vertical-center-not-xs">
            <div class="col-sm-8">
              <lawyer-name-plate :lawyer="option.scoutLawyerBridge" photo-size="small" :compact="true" :show-favorite="false" :show-lawyer-link="true"></lawyer-name-plate>
            </div>

            <div class="col-sm-4 text-right-not-xs">
              <span v-if="option.scoutLawyerBridge.rate">{{ option.scoutLawyerBridge.rate }}/hr</span>
            </div>
          </div>
        </div>

        <div class="top-10">
          <hr class="no-margin">
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-md-offset-1 top-30">
      <div class="gray-panel bottom-30">
        <div class="row tight-columns">
          <div class="col-xs-2">
            <svg-icon name="clients" class="base-icon"></svg-icon>
          </div>

          <div class="col-xs-10">
            <div class="bold-weight bottom-2">
              RFP Created By
            </div>

            <div>
              {{ rfq.creatorFullName }}
            </div>

            <div>
              {{ workspace.name }}
            </div>
          </div>
        </div>

        <div class="row tight-columns top-30">
          <div class="col-xs-2">
            <svg-icon name="calendarCheck" class="base-icon"></svg-icon>
          </div>

          <div class="col-xs-10">
            <div class="bold-weight bottom-2">
              Date Received
            </div>

            <div>
              {{ dateReceived }}
            </div>
          </div>
        </div>

        <div class="row tight-columns top-30">
          <div class="col-xs-2">
            <svg-icon name="clockDue" class="base-icon"></svg-icon>
          </div>

          <div class="col-xs-10">
            <div class="bold-weight bottom-2">
              Responses Due By
            </div>

            <div>
              {{ dueDate }}
            </div>
          </div>
        </div>

        <div class="row tight-columns top-30" v-if="hasBudget">
          <div class="col-xs-2">
            <svg-icon name="dollar" class="base-icon"></svg-icon>
          </div>

          <div class="col-xs-10">
            <div class="bold-weight bottom-2">
              Budget
            </div>

            <div>
              {{ budget }}
            </div>
          </div>
        </div>
      </div>

      <div class="gray-panel">
        <div class="row tight-columns bottom-30">
          <div class="col-xs-2">
            <svg-icon name="clients" class="base-icon"></svg-icon>
          </div>

          <div class="col-xs-10">
            <div class="bold-weight">
              Law Firm Recipients
            </div>
          </div>
        </div>

        <div class="bottom-30">
          <div class="bold-weight">
            Provisioned Recipients
          </div>

          <div class="pill-gray-text bottom-20">
            The individuals listed below are provisioned to view/respond to this RFP.
          </div>

          <div v-for="lawyer in quote.lawyersToReceiveUpdates" :key="lawyer.id" class="bottom-20">
            <div class="semibold-weight">{{ lawyer.firstName }} {{ lawyer.lastName }} {{ currentUserNameText(lawyer) }}</div>
            <div>{{ lawyer.email }}</div>
          </div>
        </div>

        <hr class="bottom-30">

        <div>
          <div class="bold-weight">Additional Notifications to:</div>
          <div class="pill-gray-text bottom-20">
            The client sent a notification about this RFP to the individuals below, who are not currently provisioned to view/respond to this RFP.
          </div>

          <collapsable-items
            :items="quote.additionalRecipients"
            :items-limit="3">
            <template #items="{ renderingItems }">
              <div v-for="recipient in renderingItems" :key="recipient.id">
                <div class="semibold-weight">{{ recipient.firstName }} {{ recipient.lastName }}</div>
                <div class="bottom-20">{{ recipient.email }}</div>
              </div>
            </template>
          </collapsable-items>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LawyerNamePlate from 'vue-app/scout/shared/lawyer-name-plate.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ScoutLawyerBridge from 'resources/scout/scout-lawyer-bridge';
import DateFilter from 'vue-app/shared/lib/date-filter';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter';
import { compact, map, orderBy } from 'lodash';
import { mapState } from 'pinia';
import useCurrentUserStore  from 'vue-app/stores/current-user.js';
import CollapsableItems from 'vue-app/shared/components/collapsable-items.vue';

export default {
  name: 'RfqDetail',

  components: {
    LawyerNamePlate,
    SvgIcon,
    CollapsableItems
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    quote: {
      type: Object,
      required: true
    },

    suggestedLawyers: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    workspace() {
      return this.quote.workspace;
    },

    supportingDocuments() {
      return this.rfq.supportingDocuments;
    },

    orderedSuggestedLawyers() {
      const suggestedLawyers = map(this.suggestedLawyers, (suggestion) => {
        suggestion.scoutLawyerBridge = new ScoutLawyerBridge(suggestion.scoutLawyerBridge);
        return suggestion;
      });

      return orderBy(suggestedLawyers, [
        'scoutLawyerBridge.lastName', 'scoutLawyerBridge.firstName', 'scoutLawyerBridge.fullName',
        'lawyer.lastName', 'lawyer.firstName', 'lawyer.fullName',
        'lawyerName'
      ]);
    },

    dueDate() {
      return DateFilter.filterDate(this.rfq.dueDate);
    },

    dateReceived() {
      return DateFilter.filter(this.quote.dateReceived);
    },

    hasCounterpartyDetails() {
      return this.rfq.counterpartyDetails && this.rfq.counterpartyDetails.length > 0;
    },

    hasEstimatedTimeline() {
      return this.rfq.estimatedTimeline && this.rfq.estimatedTimeline.length > 0;
    },

    hasPreferredRateStructure() {
      return this.rfq.preferredRateStructure && this.rfq.preferredRateStructure.length > 0;
    },

    hasAdditionalInformation() {
      return this.rfq.additionalInformation && this.rfq.additionalInformation.length > 0;
    },

    hasSupportingDocuments() {
      return this.supportingDocuments.length > 0;
    },

    hasSuggestedLawyers() {
      return this.suggestedLawyers.length > 0;
    },

    hasBudget() {
      return this.rfq.budgetMin || this.rfq.budgetMax;
    },

    budget() {
      return compact([CurrencyFilter.filter(this.rfq.budgetMin), CurrencyFilter.filter(this.rfq.budgetMax)]).join(' – ');
    }
  },

  methods: {
    currentUserNameText(lawyer) {
      return this.currentUser.email === lawyer.email ? '(You)' : '';
    }
  }
};
</script>
