<template>
  <div>
    <next-steps-stepper @step-change="next">
      <template #default="{ activeStep, moveToNextStep, moveToPrevStep }">
        <div v-if="activeStep == 1">
          <div class="shadowed-box bottom-30">
            <div class="box-content confirm-budget-content">
              <form class="a-form" novalidate>
                <div class="size-text-18px bold-weight bottom-20">
                  Confirm Budget
                </div>
                <div v-if="showInputBudget || !validBudget" class="next-steps-content">
                  <div class="next-steps-flex-content">
                    <div class="svg-circle right-15">
                      <svg-icon name="calc-check" class="base-icon medium stroke" />
                    </div>
                    <div class="box-text">
                      <span class="bold-weight size-text-15px">Confirm Budget</span>
                      <p>Input the total budget for this RFP.</p>
                    </div>
                  </div>
                  <div v-if="!showInputBudget">
                    <div class="next-steps-flex-content input-btn">
                      <button type="button" class="nv-button-blue input-budget-btn" @click="showBudgetFields">
                        Input budget
                      </button>
                    </div>
                  </div>
                  <div v-if="showInputBudget">
                    <div class="next-steps-flex-content input-with-save">
                      <currency-input-vertical
                        v-model.number="confirmedBudgetAmount"
                        id="confirmed-budget"
                        name="confirmedBudget"
                        placeholder="Input Final Budget"
                        validation-mode="eager">
                      </currency-input-vertical>
                      <button type="button" :disabled="!validBudget" class="nv-button-blue padded" @click="save">Save</button>
                      <button type="button" class="nv-button-white padded" @click="cancel">Cancel</button>
                    </div>
                  </div>
                </div>

                <div v-else class="next-steps-content completed">
                  <div class="next-steps-flex-content">
                    <div class="svg-circle right-15">
                      <svg-icon name="calc-check" class="base-icon medium stroke" />
                    </div>
                    <div class="box-text">
                      <span class="bold-weight size-text-15px">Confirmed Budget</span>
                      <p>Input the total budget for this RFP.</p>
                    </div>
                  </div>
                  <div>
                    <div class="next-steps-flex-content">
                      <div class="confirmed-content">
                        Confirmed Budget: <span class="bold-weight size-text-18px left-10">{{ formatBudget }} </span>
                        <div class="edit-budget"><a class="bold-weight" href="#" @click.prevent="editBudget">Edit</a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div v-if="confirmedBudgetAmount">
            <a class="blue-text bold-weight vertical-center flex-end" href="" @click.prevent="moveToNextStep">Continue<svg-icon name="arrow-right" class="base-icon left-10"></svg-icon></a>
          </div>
        </div>

        <div v-if="activeStep == 2">
          <div class="shadowed-box bottom-30">
            <div class="box-content confirm-budget-content">
              <form class="a-form" novalidate>
                <div class="size-text-18px bold-weight bottom-20">
                  Connect Matter
                </div>
                <div v-if="connectedMatters.length <= 0" class="next-steps-content">
                  <div class="next-steps-flex-content">
                    <div class="svg-circle right-15">
                      <svg-icon name="star-dollar" class="base-icon medium stroke"></svg-icon>
                    </div>
                    <div class="box-text">
                      <span class="bold-weight size-text-15px">Search for Matter</span>
                      <p>Connect e-billing Matter to track spend against RFP Budget.</p>
                    </div>
                  </div>
                  <div class="next-steps-flex-content input-with-save">
                    <typeahead-vertical
                      rules="required"
                      :id="'matterInput'"
                      :options="availableMatters"
                      ref="selectedMatterInput"
                      option-label-key="nameAndCode"
                      @input="setSelectedMatter"
                      placeholder="Search by Matter Name or #"
                      :force-select="true">
                    </typeahead-vertical>
                    <button type="button" :disabled="!validMatter" class="nv-button-blue padded a-button-size" @click.prevent="confirmConnectMatter">connect</button>
                  </div>
                </div>
                <div v-else class="next-steps-content completed">
                  <div class="next-steps-flex-content">
                    <div class="svg-circle right-15">
                      <svg-icon name="star-dollar" class="base-icon medium stroke"></svg-icon>
                    </div>
                    <div class="box-text">
                      <span class="bold-weight size-text-15px">Connected Matters</span>
                      <p>Connect e-billing Matter to track spend against RFP Budget.</p>
                      <priori-modal ref="connectMatterModal" modal-id="connect-matter" class="top-5">
                        <template #modal-trigger="{ openModal }">
                          <a href="" class="bold-weight" @click.prevent="openModal">+ Connect Additional Matter</a>
                        </template>

                        <template #title>
                          Add Additional Matter
                        </template>

                        <form class="a-form" novalidate>
                          <validation-observer v-slot="{ handleSubmit, failed }" ref="validationObserver">
                            <div class="bottom-30">
                              <label for="matter-input">Search by Name or Number <span class="inline-help required">*</span></label>
                              <typeahead-vertical
                                rules="required"
                                id="matter-input"
                                :options="availableMatters"
                                ref="selectedMatterInput"
                                option-label-key="nameAndCode"
                                @input="setSelectedMatter"
                                placeholder="Search by Matter Name or #"
                                :force-select="true">
                              </typeahead-vertical>
                            </div>

                            <div class="row tight-columns">
                              <div class="col-sm-4">
                                <!-- :disabled="!validMatter" -->
                                <button type="button" class="nv-button-blue padded a-button-size add-matter-btn" @click="handleSubmit(confirmConnectMatter)">Add Matter</button>
                              </div>

                              <div class="col-sm-3 top-20-xs">
                                <button type="button" class="nv-button-white padded a-button-size cancel-matter-btn" @click="closeMatterModal">Cancel</button>
                              </div>
                            </div>
                          </validation-observer>
                        </form>
                      </priori-modal>
                    </div>
                  </div>
                  <div class="next-steps-flex-content connected-matter-list">
                    <div class="connected-matter list-spacing-10" v-for="matter in connectedMatters" :key="matter.id">
                      <a class="bold-weight" target="_blank" :href="`/scout-company/matters/${matter.id}`"> {{ matter.name }} </a> <span class="text-italic matter-code">#{{ matter.code }}</span>
                      <a href="" class="blue-text" @click.prevent="unlinkMatter(matter.id, matter.name, matter.code)"><svg-icon name="trash" class="delete-btn base-icon"></svg-icon></a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div>
            <a class="blue-text bold-weight vertical-center flex-start" href="" @click.prevent="moveToPrevStep"><svg-icon name="arrow-left" class="base-icon right-10"></svg-icon>Previous</a>
          </div>
        </div>
      </template>
    </next-steps-stepper>
  </div>
</template>

<script>
import NextStepsStepper from 'vue-app/scout/client/rfqs/next-steps/stepper.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import CurrencyInputVertical from 'vue-app/shared/components/currency-input-vertical.vue';
import ScoutMatter from 'resources/scout/scout-matter.js';
import RFQ from 'src/resources/scout/rfq.js';
import { map, each } from 'lodash';
import CurrencyFormatter from 'src/vue-app/shared/misc/currency-formatter.js';
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'RfqNextSteps',

  components: {
    NextStepsStepper,
    CurrencyInputVertical,
    ValidationObserver
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    prevStep: {
      type: Object
    },
    onSave: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedTab: this.selectedTabDefault,
      showInputBudget: false,
      confirmedBudgetAmount: this.rfq.confirmedBudgetInUsCents / 100, // stored in cents but maniuplated in dollars
      matters: [],
      connectedMatters: [],
      selectedMatter: null
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    canEditRfq() {
      return this.rfq.creatorId === this.currentUser.id || this.currentUser.hasRfqWorkspaceEnabled;
    },

    availableMatters() {
      return map(this.matters, (matter) => {
        matter.nameAndCode = `${matter.name} - (${matter.code})`;
        return matter;
      });
    },

    validMatter() {
      return this.selectedMatter != null && !this.connectedMatters.some(matter => matter.id === this.selectedMatter.id);
    },

    validBudget() {
      const decimalPattern = /^\d*\.?\d+$/;
      return this.confirmedBudgetAmount != null && this.confirmedBudgetAmount > 0 && decimalPattern.test(this.confirmedBudgetAmount);
    },

    formatBudget() {
      return CurrencyFormatter.standard().format(this.confirmedBudgetAmount);
    }
  },

  mounted() {
    ScoutMatter.query({ view: 'rfq_connect_matter' }).then((matters) => {
      each(matters, (matter) => {
        if (matter.scoutRfqId == this.rfq.id) {
          this.connectedMatters.push(matter);
        }
        else if (matter.scoutRfqId == null) { // we only want them in the list if they dont have an association
          this.matters.push(matter);
        }
      });
    });
  },

  methods: {
    onSelect(tabName) {
      this.selectedTab = tabName;
    },

    showBudgetFields() {
      this.showInputBudget = true;
    },

    editBudget() {
      //this.rfq.confirmedBudgetInUsCents = 0;
      this.showInputBudget = true;
    },

    setSelectedMatter(event) {
      //we dont want this to trigger on reset
      if (event) {
        this.selectedMatter = event;
      }
    },

    save() {
      this.showInputBudget = false;
      this.rfq.confirmedBudgetInUsCents = this.confirmedBudgetAmount * 100; // convert to cents for more flexible use elsewhere
      RFQ.confirmBudget({ id: this.rfq.id, confirmed_budget_in_us_cents: this.confirmedBudgetAmount * 100 });
    },

    confirmConnectMatter() {
      ScoutMatter.update({ id: this.selectedMatter.id, scout_rfq_id: this.rfq.id });
      this.connectedMatters.push({ id: this.selectedMatter.id, name: this.selectedMatter.name, code: this.selectedMatter.code });
      this.matters = this.matters.filter(matter => matter.id !== this.selectedMatter.id);
      this.selectedMatter = null;
      this.$refs.selectedMatterInput.reset();
      if (this.$refs.connectMatterModal) {
        this.closeMatterModal();
      }
    },

    //unlink and put the matter back into searchable matters as well as remove from connected matters list
    unlinkMatter(id, name, code) {
      ScoutMatter.update({ id: id, scout_rfq_id: null });
      this.connectedMatters = this.connectedMatters.filter(matter => matter.id !== id);
      this.matters.push({ id: id, name: name, code: code });
    },

    next(eventProps) {
      this.activeStep = eventProps.newActiveStep;
    },

    cancel() {
      this.showInputBudget = false;
      this.confirmedBudgetAmount = this.rfq.confirmedBudgetInUsCents / 100;
      //this.rfq.confirmedBudgetInUsCents = this.confirmedBudgetAmount * 100;
    },

    closeMatterModal() {
      this.$refs.connectMatterModal.closeModal();
      this.$refs.selectedMatterInput.reset();
    }
  }
};
</script>

<style scoped lang="scss">
  @use "stylesheets/scout/variables" as *;
  .shadowed-box .confirm-budget-content {
    padding: 40px;
  }

  .svg-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    gap: 10px;
    border-radius: 100px;
    background: $white;
    color: $newblue-5;
    flex-shrink: 0;

    .base-icon {
      stroke: $newblue-5;
    }
  }

  .confirmed-content {
    padding: 15px 20px;
    border: 1px solid $gray-6;
    background: $bg-color;
    gap: 10px;
    width: 400px;
  }

  .required {
    color: $error-red;
  }

  .edit-budget {
    display: inline;
    float: right;
    position: relative;
    top: 3px;
  }

  .next-steps-content {
    display: flex;
    flex-direction: row;
    gap: 10px;
    background: $blue-10;
    border: 1px solid $blue-9;
    padding: 20px 30px;
    border-radius: $border-radius-base;
    align-items: center;
    justify-content: space-between;
    &.completed {
      background: $white;
      border: 1px solid $medium-gray;
      align-items: flex-start;
      .svg-circle {
        background: $blue-10;
      }
    }

    :deep(.input-with-icon), :deep(.keyword-search-container) {
      width: 280px;
    }
  }

  .input-with-save {
    display: flex;
    gap: 10px;
  }

  .next-steps-flex-content {
    display:inline-flex;
    align-items: center;
    p {
      margin: 0px;
      font-size: 14px;
    }
  }

  .step-nav-buttons {
    position: absolute;
    a {
      text-decoration: none;
    }
    svg {
      fill: $k-blue;
      position: relative;
      top: -1px;
    }
    &.continue-btn {
      right: 45px;
    }
    &.previous-btn {
      position: relative;
    }
  }

  .connected-matter-list {
    display: flex;
    flex-direction: column;
    .connected-matter {
      padding: 15px 20px;
      border: 1px solid $gray-6;
      background: $bg-color;
      gap: 10px;
      width: 400px;
      position: relative;

      svg {
        position: absolute;
        right: 20px;
        top: 16px;
      }
      .matter-code{
        color: $pill-gray;
      }
    }
  }
  .input-budget-btn {
    width: 198px;
  }
  .add-matter-btn {
    width: 137px;
  }

  .cancel-matter-btn {
    width: 102px;
  }
</style>

